export const environment = {
  production: true,
  apiBase: 'https://fini.dev',
  apiRoot: '/json/',
  nsBase: 'wss://notifs.fini.dev/notifications',
  loginProviders: {
    discord: {
      clientId: '975408392279556096',
    },
  },
  theme: {
    light: {
      primary: '#e6794c',
    },
    dark: {
      primary: '#983b15',
    },
  },
  release: 'e02bb9c9e7e61e5ded0fcf41c32df2b9369e6c9c',

  stripeKey:
    'pk_live_51K3ProC4xQ2lMLBfnMIySdsYToDgS8dRrukumVm5roLi9zP2leF50rEa8ofpFYNjbJheYPDX0iXwRTkWaFj1M7Jc00Zq53jcdd',
};
